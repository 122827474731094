import { get, post, put, del } from '../../utils/request';

export const getAllEvents = async () => {
  const res = await get('/event');
  if (res.success) {
    return {
      events: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const createEvent = async ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  const res = await post('/event', {
    name,
    description,
  });
  if (res.success) {
    return {
      event: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const updateEvent = async ({
  eventId,
  ...body
}: {
  eventId: string;
  name?: string;
  description?: string;
}) => {
  const res = await put(`/event/${eventId}`, body);
  if (res.success) {
    return {
      event: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const deleteEvent = async ({ eventId }: { eventId: string }) => {
  const res = await del(`/event/${eventId}`);
  if (res.success) {
    return {
      event: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};
