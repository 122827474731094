import React from 'react';
import { Route, Switch } from 'react-router';

import Main from './main';
import GroupView from './group/[groupId]';
import CreateView from './group/create';

import useAuth from '../../../hooks/useAuth';

const Index = ({ match, history }) => {
  useAuth(history);
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${match.path}`} component={Main} key="main" />
        <Route
          exact
          path={`${match.path}/group/create`}
          component={CreateView}
          key="create-view"
        />
        <Route
          path={`${match.path}/group/:groupId`}
          component={GroupView}
          key="group-view"
        />
      </Switch>
    </React.Fragment>
  );
};

export default Index;
