import { get, post, put, del } from '../../utils/request';
import { QRUsage } from '../../types';

export const getAllGroups = async () => {
  const res = await get(`/group`);
  if (res.success) {
    return {
      groups: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const getAllGroupsByEventId = async ({ eventId }: { eventId: string }) => {
  const res = await get(`/event/${eventId}/group`);
  if (res.success) {
    return {
      groups: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const createGroup = async ({
  eventId,
  name,
  usage,
}: {
  eventId: string;
  name: string;
  usage: QRUsage;
}) => {
  const res = await post(`/event/${eventId}/group`, {
    name,
    usage,
  });
  if (res.success) {
    return {
      group: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const updateGroup = async ({
  eventId,
  qrTouchPointGroupId,
  ...parmas
}: {
  eventId: string;
  qrTouchPointGroupId: string;
  name?: string;
  usage?: QRUsage;
}) => {
  const res = await put(
    `/event/${eventId}/group/${qrTouchPointGroupId}`,
    parmas
  );
  if (res.success) {
    return {
      group: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const removeGroup = async ({
  eventId,
  qrTouchPointGroupId,
}: {
  eventId: string;
  qrTouchPointGroupId: string;
}) => {
  const res = await del(`/event/${eventId}/group/${qrTouchPointGroupId}`);
  if (res.success) {
    return {
      group: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};
