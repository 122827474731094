import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonGroup } from '@atlaskit/button';
import Select, { CheckboxSelect } from '@atlaskit/select';
import Form, { Field, FormFooter, HelperMessage } from '@atlaskit/form';
import { ProgressDots } from '@atlaskit/progress-indicator';
import TextField from '@atlaskit/textfield';
import useEvent from '../../../hooks/useEvent';
import useGroup from '../../../hooks/useGroup';
import {
  createSurvey,
  addGroups,
  getSurvey,
  editSurvey,
} from '../../../services/api/survey';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import { Helmet } from 'react-helmet';
import useSurvey from '../../../hooks/useSurvey';

export default ({ match, history }) => {
  const { surveyId } = match.params as { surveyId: string };
  const { surveys, getAllSurvey } = useSurvey();
  const [survey, setSurvey] = useState();
  const { events, getAllEvents } = useEvent();
  const [eventOptions, setEventOptions] = useState([]);
  const [selectingEvent, setSelectingEvent] = useState('');
  const { groups, getAllGroups } = useGroup();
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectingGroups, setSelectingGroups] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    console.log('surveyId', surveyId);
    getSurvey({ surveyId }).then(data => {
      console.log(data);
      setSurvey(data);
      getAllEvents();
      getAllGroups();
    });
  }, []);

  useEffect(() => {
    if (events.length === 0) return;
    setEventOptions(
      events.map(event => ({ value: event.id, label: event.name }))
    );
  }, [events]);

  useEffect(() => {
    setGroupOptions(
      groups
        .filter(g => g.event.id === selectingEvent)
        .map(g => ({
          value: g.id,
          label: g.name,
        }))
    );
  }, [selectingEvent]);

  useEffect(() => {
    console.log(groups);
    setGroupOptions(
      groups.map(g => ({
        value: g.id,
        label: g.name,
      }))
    );
    if (survey) {
      setSelectingGroups(
        survey.groups.map(g => ({
          value: g.id,
          label: g.name,
        }))
      );
    }
  }, [groups]);

  const nextStep = () => setProgress(progress < 2 ? progress + 1 : progress);

  const filterGroupOption = eventId => option => {
    // const isSelecting = () => {
    //   return !!selectingGroups.find(g => g.value.id === option.value.id);
    // };
    if (eventId.length === 0) return true;
    return option.value.eventId === eventId;
  };

  const basicForm = () => {
    if (!survey) return null;
    return (
      <React.Fragment>
        <Helmet>
          <title>Survey - Edit</title>
        </Helmet>
        <Form
          onSubmit={async data => {
            try {
              console.log(data);
              const { event, ...body } = data;
              const {
                survey: updatedSurvey,
                errorMsg: updateSurveyErrorMsg,
              } = await editSurvey({
                surveyId,
                survey: {
                  ...body,
                  groups: body.groups.map(group => group.value),
                },
              });
              console.log(updatedSurvey);
              setSurvey(updatedSurvey);
              nextStep();
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <Field
                name="name"
                label="Survey Name"
                isRequired
                defaultValue={survey.name}
              >
                {({ fieldProps, error }) => (
                  <TextField autoComplete="off" {...fieldProps} />
                )}
              </Field>
              <Field name="event" label="Event" defaultValue="">
                {({ fieldProps: { onChange }, error }) => (
                  <Fragment>
                    <Select
                      className="multi-select"
                      classNamePrefix="react-select"
                      options={eventOptions}
                      placeholder={
                        eventOptions.length > 0
                          ? 'Choose a event'
                          : 'Please create an event first'
                      }
                      isClearable
                      isDisabled={eventOptions.length === 0}
                      onChange={obj => {
                        setSelectingEvent(obj ? obj.value : '');
                        onChange(obj ? obj.value : '');
                      }}
                    />
                  </Fragment>
                )}
              </Field>
              <Field
                name="groups"
                label="Group"
                isRequired
                defaultValue={selectingGroups}
              >
                {({ fieldProps: { onChange }, error }) => (
                  <Fragment>
                    <CheckboxSelect
                      value={selectingGroups}
                      options={groupOptions}
                      onChange={values => {
                        console.log(values);
                        setSelectingGroups(values ? values : []);
                        onChange(values ? values.map(val => val.value) : []);
                      }}
                      isMulti
                    />
                  </Fragment>
                )}
              </Field>
              <Field name="url" label="Url" defaultValue={survey.url}>
                {({ fieldProps, error }) => (
                  <Fragment>
                    <TextField autoComplete="off" {...fieldProps} />
                    <HelperMessage>
                      If this field is filled, the client will be redirect to
                      this link immediately when they scanned the qr code.
                    </HelperMessage>
                  </Fragment>
                )}
              </Field>
              <FormFooter>
                <ButtonGroup>
                  <Button onClick={() => history.goBack()} appearance="subtle">
                    Back
                  </Button>
                  <Button
                    type="submit"
                    appearance="primary"
                    isLoading={submitting}
                  >
                    Next
                  </Button>
                </ButtonGroup>
              </FormFooter>
            </form>
          )}
        </Form>
      </React.Fragment>
    );
  };

  const endSession = () => {
    return (
      <SurveyEndSession>
        <CheckCircleOutlineIcon size="large" label="end" />
        <SurveyBackToViewBtn onClick={() => history.goBack()}>
          DONE
        </SurveyBackToViewBtn>
      </SurveyEndSession>
    );
  };

  return (
    <PageWrapper>
      <SurveyCreateHeader>
        <h3>Survey - Edit - {surveyId}</h3>
      </SurveyCreateHeader>
      <SurveyCreateFormSession>
        <ProgressDots selectedIndex={progress} values={['basic', 'end']} />
        {progress === 0 && basicForm()}
        {progress === 1 && endSession()}
      </SurveyCreateFormSession>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 100px);
  border-radius: 10px;
`;

const SurveyCreateHeader = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px;
`;

const SurveyCreateFormSession = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SurveyQuestionSession = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SurveyEndSession = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SurveyBackToViewBtn = styled(Button)`
  margin-top: 10px;
`;
