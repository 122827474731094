import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Col, Row } from 'react-awesome-styled-grid';
import { Helmet } from 'react-helmet';
import QRCode from 'qrcode';

/**
 * Components
 */
import Lozenge from '@atlaskit/lozenge';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import WorldIcon from '@atlaskit/icon/glyph/world';
import ArrowDownCircleIcon from '@atlaskit/icon/glyph/arrow-down-circle';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';

/**
 * Hooks
 */
import useGroup from '../../../../hooks/useGroup';
import useQR from '../../../../hooks/useQR';

/**
 * API
 */
import {
  createQR,
  unactivateQR,
  terminateQR,
  kiaQR,
} from '../../../../services/api/qr';
import { getAllGroupsByEventId } from '../../../../services/api/group';

/**
 * Table Setting
 */
const head = {
  cells: [
    {
      key: 'id',
      content: 'ID',
      isSortable: true,
    },
    {
      key: 'locationDescription',
      content: 'Location Description',
      isSortable: false,
    },
    {
      key: 'status',
      content: 'Status',
    },
    {
      key: 'scanCount',
      content: 'Scan Count',
    },
    {
      key: 'location',
      content: 'Location',
    },
    {
      key: 'action',
      content: 'Action',
    },
    {
      key: 'qr',
      content: 'QR',
    },
  ],
};

const fromQRsToRows = ({ qrs, eventId, groupId, onActionSelected, qrImages }) =>
  qrs.map((qr, index) => ({
    cells: [
      {
        key: 'id',
        content: qr.id,
      },
      {
        key: 'locationDescription',
        content: qr.locationDescription,
      },
      {
        key: 'status',
        content: (
          <Lozenge appearance="default">
            <IDSpan>{qr.status}</IDSpan>
          </Lozenge>
        ),
      },
      {
        key: 'scanCount',
        content: qr.scanCount,
      },
      {
        key: 'location',
        content: (
          <Button
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${qr.latitude},${qr.longitude}`,
                '_blank'
              )
            }
            appearance="primary"
            iconBefore={<WorldIcon label="location" />}
            disabled={!qr.latitude || !qr.longitude}
            isDisabled={!qr.latitude || !qr.longitude}
          ></Button>
        ),
      },
      {
        key: 'action',
        content: (
          <DropdownMenu
            trigger="Action"
            triggerType="button"
            onOpenChange={async e => {
              if (!e.isOpen) {
                switch (e.event.target.innerText) {
                  case 'Activate':
                    console.log('Activate');
                    break;
                  case 'Unactivate':
                    await unactivateQR({ qrId: qr.id });
                    onActionSelected();
                    break;
                  case 'Terminate':
                    await terminateQR({ qrId: qr.id });
                    onActionSelected();
                    break;
                  case 'KIA':
                    await kiaQR({ qrId: qr.id });
                    onActionSelected();
                    break;

                  default:
                    break;
                }
              }
            }}
          >
            <DropdownItemGroup>
              <DropdownItem id="activate">Activate</DropdownItem>
              <DropdownItem id="unactivate">Unactivate</DropdownItem>
              <DropdownItem id="qr">Terminate</DropdownItem>
              <DropdownItem id="kia">KIA</DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        ),
      },
      {
        key: 'qr',
        content: (
          <a href={qrImages.length > 0 && qrImages[index]} download={qr.id}>
            <Button
              iconBefore={<ArrowDownCircleIcon label="download" />}
            ></Button>
          </a>
        ),
      },
    ],
  }));

export default ({ match }: RouteComponentProps) => {
  const [loading, setLoading] = useState(false);

  const { eventId, groupId } = match.params as {
    eventId: string;
    groupId: string;
  };

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getAllGroupsByEventId({ eventId }).then(({ groups }) => {
      console.log(groups);
      setGroups(groups);
    });
  }, []);

  const [group, setGroup] = useState(
    {} as {
      id: string;
      name: string;
      usage: string;
    }
  );

  useEffect(() => {
    const tempGroup = groups.find(g => g.id === groupId);
    if (tempGroup) {
      setGroup(tempGroup);
    }
  }, [groups]);

  const { qrs, getAllQRByGroupId } = useQR();
  const [qrImages, setQRImages] = useState([]);
  const [qrImage, setQRImage] = useState('');

  useEffect(() => {
    getAllQRByGroupId({ eventId, groupId });
  }, [group]);

  useEffect(() => {
    const images = [];
    const qrDataUrl = [];
    qrs.forEach(qr => {
      QRCode.toDataURL(`https://qr.eventbinder.app/${qr.id}`).then(dataUrl => {
        qrDataUrl.push(dataUrl);
        const image = new Image();
        image.onload = () => {
          images.push({
            id: qr.id,
            image,
          });
          if (images.length === qrs.length) {
            setQRImages(images);
            setRows(
              fromQRsToRows({
                qrs,
                eventId,
                groupId,
                onActionSelected: () => {
                  getAllQRByGroupId({ eventId, groupId });
                },
                qrImages: qrDataUrl,
              })
            );
          }
        };
        image.src = dataUrl;
      });
    });
  }, [qrs]);

  const [rows, setRows] = useState([]);

  const createManyQRs = async (num: number) => {
    try {
      setLoading(true);
      for (let index = 0; index < num; index++) {
        await createQR({ eventId, groupId });
      }
      getAllQRByGroupId({ eventId, groupId });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onCreateBtnPress = async () => {
    const numOfQRToCreate = window.prompt(
      'How many QR do you want to create?',
      '1'
    );
    await createManyQRs(Number(numOfQRToCreate));
  };

  const splitInBatches = (array: any[], chunkSize: number) => {
    return [].concat.apply(
      [],
      array.map(function(elem, i) {
        return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
      })
    );
  };

  const onPrintBtnPress = async () => {
    // QR code size
    const size = 96.8 * 2.7;
    const gridSize = 416;
    const paddingSize = 40;
    // const size = 248;
    const canvasWidth = 2480;
    const canvasHeight = 3508;
    const batches = splitInBatches(qrImages, 35);
    console.log(batches);
    for (let i = 0; i < batches.length; i++) {
      const batch = batches[i];
      let canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = '20px serif';
      let x = 0;
      let y = 0;
      for (let index = 0; index < batch.length; index++) {
        const qrImage = batch[index];
        const qrX = (paddingSize * 2 + gridSize) / 2 - size / 2;
        const qrY = (paddingSize * 2 + gridSize) / 2 - size / 2;
        ctx.drawImage(
          qrImage.image,
          x * (paddingSize + gridSize) + qrX,
          y * (paddingSize + gridSize) + qrY,
          size,
          size
        );
        ctx.strokeRect(
          x * (paddingSize + gridSize) + paddingSize,
          y * (paddingSize + gridSize) + paddingSize,
          gridSize,
          gridSize
        );
        ctx.fillStyle = '#000000';
        ctx.fillText(
          String(i * 35 + (index + 1)),
          x * (paddingSize + gridSize) + paddingSize * 2,
          y * (paddingSize + gridSize) + paddingSize * 2
        );
        ctx.fillText(
          qrImage.id,
          x * (paddingSize + gridSize) + paddingSize * 2 + 40,
          y * (paddingSize + gridSize) + paddingSize * 2
        );
        if (x === 4) {
          if (y + 1 === 6) {
            y = 0;
            x = 0;
            break;
          }
          y++;
          x = 0;
        } else {
          x++;
        }
      }
      const base64 = canvas.toDataURL();
      // setQRImage(base64);
      // const qrDownloadLink = document.querySelector(
      //   '.qr-download-link'
      // ) as HTMLAnchorElement;
      // setTimeout(() => qrDownloadLink.click());
      var a = document.createElement('a');
      a.setAttribute('href', base64);
      a.setAttribute('download', `group-${group.id}-${i + 1}.jpeg`);
      a.click();
    }
  };

  return (
    <PageWrapper>
      <Helmet>
        <title>Group - View</title>
      </Helmet>
      <Row>
        <Col xs={4} sm={8} md={2}>
          <Section>
            <SectionName>Group ID: </SectionName>
            <Lozenge appearance="default">
              <IDSpan>{groupId}</IDSpan>
            </Lozenge>
          </Section>
        </Col>
        <Col xs={4} sm={8} md={3}>
          <Section>
            <SectionName>Name: </SectionName>
            <Lozenge appearance="default">
              <IDSpan>{group.name}</IDSpan>
            </Lozenge>
          </Section>
        </Col>
        <Col xs={4} sm={8} md={3}>
          <Section>
            <SectionName>Usage: </SectionName>
            <Lozenge appearance="default">
              <IDSpan>{group.usage}</IDSpan>
            </Lozenge>
          </Section>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonSection>
            <Button onClick={onCreateBtnPress}>Create QR Touch Points</Button>
          </ButtonSection>
        </Col>
        <Col>
          <ButtonSection>
            <Button onClick={onPrintBtnPress}>Print All QR</Button>
            <a
              className="qr-download-link"
              href={qrImage}
              download={`group-${group.id}.jpeg`}
              style={{ display: 'none' }}
            ></a>
          </ButtonSection>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableSection>
            <TableWrapper>
              <DynamicTable
                head={head}
                rows={rows}
                rowsPerPage={10}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={loading}
                onSort={() => console.log('onSort')}
                onSetPage={() => console.log('onSetPage')}
              />
            </TableWrapper>
          </TableSection>
        </Col>
      </Row>
    </PageWrapper>
  );
};

const Section = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  margin: 5px;
  display: flex;
  align-items: center;
`;

const ButtonSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionName = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const IDSpan = styled.span`
  font-size: 16px;
`;

const TableSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableWrapper = styled.div`
  min-width: 500px;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 20px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
