import React from 'react';
import { Route, Switch } from 'react-router';

import Main from './main';
import Create from './create';
import View from './[eventId]';
import GroupView from './[eventId]/group/[groupId]';

import useAuth from '../../hooks/useAuth';

const Index = ({ match, history }) => {
  useAuth(history);
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${match.path}`} component={Main} key="main" />
        <Route
          exact
          path={`${match.path}/create`}
          component={Create}
          key="create"
        />
        <Route path={`${match.path}/:eventId`} component={View} key="view" />
        <Route
          path={`${match.path}/:eventId/group/:groupId`}
          component={GroupView}
          key="group-view"
        />
      </Switch>
    </React.Fragment>
  );
};

export default Index;
