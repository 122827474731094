import { create } from 'apisauce';
import { getToken } from '../utils/cookie';

interface APIResponse {
  success: boolean;
  data?: any;
  errorMsg?: string;
}

const errorHandler = (problem: string) => {
  console.error(problem);
  switch (problem) {
    case 'CLIENT_ERROR':
      return {
        success: false,
        errorMsg: 'Something went wrong!',
      };

    case 'SERVER_ERROR':
      return {
        success: false,
        errorMsg: 'Something went wrong!',
      };

    case 'TIMEOUT_ERROR':
      return {
        success: false,
        errorMsg: 'It takes too much time! Try again!',
      };

    case 'CONNECTION_ERROR':
      return {
        success: false,
        errorMsg: 'Our service is under maintainence. Sorry!',
      };

    case 'NETWORK_ERROR':
      return {
        success: false,
        errorMsg:
          'It seems that you have no network! Please check your network setting.',
      };

    case 'CANCEL_ERROR':
      return {
        success: false,
        errorMsg: `You've cancelled the action. Please try again.`,
      };

    default:
      return {
        success: false,
        errorMsg: `Unknown`,
      };
  }
};

export const api = create({
  baseURL:
    process.env.NODE_ENV !== 'development'
      ? 'https://api.eventbinder.app'
      : 'http://localhost:8080',
});

export const setAPIToken = token =>
  api.setHeader('Authorization', `Bearer ${token}`);

export const removeAPIToken = () => api.setHeader('Authorization', ``);

export const checkIfTokenExist = () => {
  const token = getToken();
  if (token && token.length > 0) {
    setAPIToken(token);
  }
};

export const get = async (url, query?: any): Promise<APIResponse> => {
  checkIfTokenExist();
  const res = await api.get(url, query);
  if (res.ok) {
    return res.data as APIResponse;
  } else {
    return errorHandler(res.problem);
  }
};

export const post = async (url, body?: any): Promise<APIResponse> => {
  checkIfTokenExist();
  const res = await api.post(url, body);
  if (res.ok) {
    return res.data as APIResponse;
  } else {
    return errorHandler(res.problem);
  }
};

export const put = async (url, body?: any): Promise<APIResponse> => {
  checkIfTokenExist();
  const res = await api.put(url, body);
  if (res.ok) {
    return res.data as APIResponse;
  } else {
    return errorHandler(res.problem);
  }
};

export const del = async (url, body?: any): Promise<APIResponse> => {
  checkIfTokenExist();
  const res = await api.delete(url, body);
  if (res.ok) {
    return res.data as APIResponse;
  } else {
    return errorHandler(res.problem);
  }
};
