import React from 'react';
import { Route, Switch } from 'react-router';

import Main from './main';
import Create from './create';
import SurveyView from './[surveyId]';
import SurveyEdit from './[surveyId]/edit';

import useAuth from '../../hooks/useAuth';

const Index = ({ match, history }) => {
  useAuth(history);
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${match.path}`} component={Main} key="main" />
        <Route
          exact
          path={`${match.path}/create`}
          component={Create}
          key="create"
        />
        <Route
          path={`${match.path}/:surveyId/edit`}
          component={SurveyEdit}
          key="survey-edit"
        />
        <Route
          path={`${match.path}/:surveyId`}
          component={SurveyView}
          key="survey-view"
        />
      </Switch>
    </React.Fragment>
  );
};

export default Index;
