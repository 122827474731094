import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonGroup } from '@atlaskit/button';
import Select, { CheckboxSelect } from '@atlaskit/select';
import Form, { Field, FormFooter, HelperMessage } from '@atlaskit/form';
import { ProgressDots } from '@atlaskit/progress-indicator';
import TextField from '@atlaskit/textfield';
import useEvent from '../../hooks/useEvent';
import useGroup from '../../hooks/useGroup';
import { createSurvey, addGroups } from '../../services/api/survey';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import { Helmet } from 'react-helmet';

export default ({ history, edit }: { history: any; edit?: boolean }) => {
  const { events, getAllEvents } = useEvent();
  const [eventOptions, setEventOptions] = useState([]);
  const [selectingEvent, setSelectingEvent] = useState('');
  const { groups, getAllGroups } = useGroup();
  const [groupOptions, setGroupOptions] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    getAllEvents();
    getAllGroups();
  }, []);

  useEffect(() => {
    if (events.length === 0) return;
    setEventOptions(
      events.map(event => ({ value: event.id, label: event.name }))
    );
  }, [events]);

  useEffect(() => {
    setGroupOptions(
      groups
        .filter(group => group.event.id === selectingEvent)
        .map(group => ({ value: group.id, label: group.name }))
    );
  }, [selectingEvent]);

  useEffect(() => {
    setGroupOptions(
      groups.map(group => ({ value: group.id, label: group.name }))
    );
  }, [groups]);

  const nextStep = () => setProgress(progress < 2 ? progress + 1 : progress);

  const basicForm = () => {
    return (
      <React.Fragment>
        <Helmet>
          <title>Survey - View</title>
        </Helmet>
        <Form
          onSubmit={async data => {
            try {
              const {
                survey,
                errorMsg: createSurveyErrorMsg,
              } = await createSurvey({
                name: data.name,
                ...(data.url && {
                  url: data.url,
                }),
                groups: data.groups,
              });
              nextStep();
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <Field name="name" label="Survey Name" isRequired defaultValue="">
                {({ fieldProps, error }) => (
                  <TextField autoComplete="off" {...fieldProps} />
                )}
              </Field>
              <Field name="event" label="Event" defaultValue="" isRequired>
                {({ fieldProps: { onChange }, error }) => (
                  <Fragment>
                    <Select
                      className="multi-select"
                      classNamePrefix="react-select"
                      defaultOptions
                      options={eventOptions}
                      placeholder={
                        eventOptions.length > 0
                          ? 'Choose a event'
                          : 'Please create an event first'
                      }
                      isDisabled={eventOptions.length === 0}
                      onChange={obj => {
                        setSelectingEvent(obj.value);
                        onChange(obj.value);
                      }}
                    />
                  </Fragment>
                )}
              </Field>
              <Field name="groups" label="Group" isRequired>
                {({ fieldProps: { onChange }, error }) => (
                  <Fragment>
                    <CheckboxSelect
                      options={groupOptions}
                      isMulti
                      onChange={values => {
                        onChange(values.map(val => val.value));
                      }}
                    />
                  </Fragment>
                )}
              </Field>
              <Field name="url" label="Url" defaultValue="">
                {({ fieldProps, error }) => (
                  <Fragment>
                    <TextField autoComplete="off" {...fieldProps} />
                    <HelperMessage>
                      If this field is filled, the client will be redirect to
                      this link immediately when they scanned the qr code.
                    </HelperMessage>
                  </Fragment>
                )}
              </Field>
              <FormFooter>
                <ButtonGroup>
                  <Button onClick={() => history.goBack()} appearance="subtle">
                    Back
                  </Button>
                  <Button
                    type="submit"
                    appearance="primary"
                    isLoading={submitting}
                  >
                    Next
                  </Button>
                </ButtonGroup>
              </FormFooter>
            </form>
          )}
        </Form>
      </React.Fragment>
    );
  };

  const endSession = () => {
    return (
      <SurveyEndSession>
        <CheckCircleOutlineIcon size="large" label="end" />
        <SurveyBackToViewBtn onClick={() => history.goBack()}>
          DONE
        </SurveyBackToViewBtn>
      </SurveyEndSession>
    );
  };

  return (
    <PageWrapper>
      <SurveyCreateHeader>
        <h3>Survey - Create</h3>
      </SurveyCreateHeader>
      <SurveyCreateFormSession>
        <ProgressDots selectedIndex={progress} values={['basic', 'end']} />
        {progress === 0 && basicForm()}
        {progress === 1 && endSession()}
      </SurveyCreateFormSession>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 100px);
  border-radius: 10px;
`;

const SurveyCreateHeader = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px;
`;

const SurveyCreateFormSession = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SurveyQuestionSession = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SurveyEndSession = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SurveyBackToViewBtn = styled(Button)`
  margin-top: 10px;
`;
