import React from 'react';
import { Route, Switch } from 'react-router';

import Question from './question';

import useAuth from '../../../hooks/useAuth';

const Index = ({ match, history }) => {
  useAuth(history);
  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={`${match.path}/question`}
          component={Question}
          key="question"
        />
      </Switch>
    </React.Fragment>
  );
};

export default Index;
