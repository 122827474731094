import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { QuestionBlock, Selection } from '../../../../../types';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import uuid from 'uuid/v4';
import { Button } from '@atlaskit/button/dist/cjs/components/Button';

interface Props {
  questionBlock: QuestionBlock;
  questionBlocks: QuestionBlock[];
  onEditingQuestionBlockChanged(questionBlock: QuestionBlock): void;
}

const questionTypeOptions = [
  { label: 'Text', value: 'TEXT' },
  { label: 'Long Text', value: 'LONGTEXT' },
  { label: 'Select', value: 'SELECT' },
  { label: 'Multiple Select', value: 'MULTIPLESELECT' },
  { label: 'Pitch', value: 'PITCH' },
];

export default ({
  questionBlock,
  questionBlocks,
  onEditingQuestionBlockChanged,
}: Props) => {
  const [localQuestionBlock, setLocalQuestionBlock] = useState(questionBlock);
  const editLocalQuestionBlock = payload => {
    // console.log({
    //   ...localQuestionBlock,
    //   ...payload,
    // });
    setLocalQuestionBlock({
      ...localQuestionBlock,
      ...payload,
    });
  };

  const isSelectType = () =>
    localQuestionBlock.type === 'SELECT' ||
    localQuestionBlock.type === 'MULTIPLESELECT';

  const questionBlocksOptions = questionBlocks
    .filter(qb => qb.id !== localQuestionBlock.id)
    .map(qb => ({
      label: `${qb.question}(${qb.type})`,
      value: qb.id,
    }));

  const questionBlocksOptionValue = questionId =>
    questionBlocksOptions.find(option => option.value === questionId);

  // Selection Related
  const [selections, setSelections] = useState<Selection[]>(
    questionBlock.selections
  );

  useEffect(() => {
    setLocalQuestionBlock({
      ...localQuestionBlock,
      selections,
    });
  }, [selections]);

  const atLeastOneSelectionHasConnection = () =>
    selections
      .map(selection => selection.nextQuestion.length > 0)
      .includes(true);

  const addSelection = () => () => {
    setSelections([
      ...selections,
      {
        id: uuid(),
        label: '',
        value: '',
        url: '',
        nextQuestion: '',
      },
    ]);
  };

  const deleteSelection = id => {
    let tempSelection = [...selections];
    const index = selections.findIndex(selection => selection.id === id);
    if (index === -1) return;
    tempSelection.splice(index, 1);
    setSelections(tempSelection);
  };

  const editSelection = (id, payload) => {
    let tempSelection = [...selections];
    const index = selections.findIndex(selection => selection.id === id);
    if (index === -1) return;
    const editingSelection = selections.find(selection => selection.id === id);
    tempSelection.splice(index, 1, {
      ...editingSelection,
      ...payload,
    });
    setSelections(tempSelection);
  };

  const onDeleteSelection = id => () => deleteSelection(id);

  return (
    <EditingQuestionBlockModalContainer>
      {/* Question Type */}
      <InputContainer>
        <InputLabel>Type</InputLabel>
        <Select
          className="single-select"
          classNamePrefix="react-select"
          options={questionTypeOptions}
          placeholder="Choose a question type"
          onChange={option => editLocalQuestionBlock({ type: option.value })}
          value={questionTypeOptions.find(
            option => option.value === localQuestionBlock.type
          )}
        />
      </InputContainer>

      {/* Question */}
      <InputContainer>
        <InputLabel>Question</InputLabel>
        <TextField
          value={localQuestionBlock.question}
          onChange={e => {
            const currentValue = e.currentTarget.value;
            editLocalQuestionBlock({ question: currentValue });
            // debounce(() => editLocalQuestionBlock({question: currentValue}), 1500);
          }}
        />
      </InputContainer>

      {/* Description */}
      <InputContainer>
        <InputLabel>Description</InputLabel>
        <TextArea
          resize="auto"
          value={localQuestionBlock.description}
          onChange={e =>
            editLocalQuestionBlock({ description: e.currentTarget.value })
          }
        />
      </InputContainer>

      {/* URL */}
      {!atLeastOneSelectionHasConnection() &&
        localQuestionBlock.nextQuestion.length === 0 && (
          <InputContainer>
            <InputLabel>URL</InputLabel>
            <TextField
              value={localQuestionBlock.url}
              onChange={e =>
                editLocalQuestionBlock({ url: e.currentTarget.value })
              }
            />
          </InputContainer>
        )}

      {/* Next Question */}
      {!atLeastOneSelectionHasConnection() &&
        localQuestionBlock.url.length === 0 && (
          <InputContainer>
            <InputLabel>Next Question</InputLabel>
            <Select
              className="single-select"
              classNamePrefix="react-select"
              options={questionBlocksOptions}
              placeholder="Choose a next question"
              onChange={option => {
                if (!option)
                  return editLocalQuestionBlock({ nextQuestion: '' });
                editLocalQuestionBlock({ nextQuestion: option.value });
              }}
              value={questionBlocksOptionValue(localQuestionBlock.nextQuestion)}
              isClearable
            />
          </InputContainer>
        )}

      {/* Selection */}
      {isSelectType() && (
        <InputContainer>
          <InputLabel>
            Selections{' '}
            <span onClick={addSelection()}>
              <FontAwesomeIcon icon={faPlusSquare} color="#42526c" />
            </span>
          </InputLabel>
          <SelectionWrapper>
            {selections.map((selection, index) => (
              <SelectionContainer key={index}>
                <SelectionInputContainer>
                  <SelectionInputLabel>
                    Label
                    <span onClick={onDeleteSelection(selection.id)}>
                      <FontAwesomeIcon icon={faTimesCircle} color="#42526c" />
                    </span>
                  </SelectionInputLabel>
                  <TextField
                    placeholder="Label"
                    value={selection.label}
                    onChange={e =>
                      editSelection(selection.id, {
                        ...selection,
                        label: e.currentTarget.value,
                      })
                    }
                  />
                </SelectionInputContainer>
                <SelectionInputContainer>
                  <SelectionInputLabel>Value</SelectionInputLabel>
                  <TextField
                    placeholder="Value"
                    value={selection.value}
                    onChange={e =>
                      editSelection(selection.id, {
                        ...selection,
                        value: e.currentTarget.value,
                      })
                    }
                  />
                </SelectionInputContainer>
                {localQuestionBlock.type === 'SELECT' &&
                  localQuestionBlock.url.length === 0 &&
                  localQuestionBlock.nextQuestion.length === 0 &&
                  selection.url.length === 0 && (
                    <SelectionInputContainer>
                      <SelectionInputLabel>Next Question</SelectionInputLabel>
                      <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        options={questionBlocksOptions}
                        placeholder="Choose a next question"
                        onChange={option =>
                          editSelection(selection.id, {
                            ...selection,
                            nextQuestion: option.value,
                          })
                        }
                        value={questionBlocksOptionValue(
                          selection.nextQuestion
                        )}
                        isClearable
                      />
                    </SelectionInputContainer>
                  )}
                {localQuestionBlock.type === 'SELECT' &&
                  localQuestionBlock.url.length === 0 &&
                  localQuestionBlock.nextQuestion.length === 0 &&
                  !selection.nextQuestion && (
                    <SelectionInputContainer>
                      <SelectionInputLabel>URL</SelectionInputLabel>
                      <TextField
                        placeholder="URL"
                        value={selection.url}
                        onChange={e =>
                          editSelection(selection.id, {
                            ...selection,
                            url: e.currentTarget.value,
                          })
                        }
                      />
                    </SelectionInputContainer>
                  )}
              </SelectionContainer>
            ))}
          </SelectionWrapper>
        </InputContainer>
      )}

      <Button onClick={() => onEditingQuestionBlockChanged(localQuestionBlock)}>
        Okay
      </Button>
    </EditingQuestionBlockModalContainer>
  );
};

const EditingQuestionBlockModalContainer = styled.div`
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const InputLabel = styled.div`
  font-weight: bolder;
  margin-bottom: 10px;
`;

const SelectionContainer = styled.div`
  border: 1.5px dashed #42526c;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

const SelectionWrapper = styled.div`
  border: 1.5px solid #42526c;
  border-radius: 5px;
  padding: 10px;
`;

const SelectionInputContainer = styled.div`
  padding: 10px;
`;

const SelectionInputLabel = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;
