import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import TextField from '@atlaskit/textfield';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, { Field, FormFooter, ErrorMessage } from '@atlaskit/form';
import { createGroup } from '../../../../services/api/group';
import { RouteComponentProps } from 'react-router';
import Select from '@atlaskit/select';
import { Helmet } from 'react-helmet';

export default ({ match, history }: RouteComponentProps) => {
  const { eventId } = match.params as { eventId: string };
  return (
    <PageWrapper>
      <Helmet>
        <title>Group - Create</title>
      </Helmet>
      <h2>Group - Create</h2>
      <Form
        onSubmit={async data => {
          const params = {
            ...data,
            usage: data.usage.value,
          };
          console.log(params);
          const { group, errorMsg } = await createGroup({
            eventId,
            ...params,
          });
          if (errorMsg) {
            // Display error message
            return;
          }
          history.goBack();
          return;
        }}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Field name="name" label="Group Name" isRequired defaultValue="">
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextField autoComplete="off" {...fieldProps} />
                </Fragment>
              )}
            </Field>
            <Field
              name="usage"
              label="Usage"
              defaultValue=""
              isRequired
              validate={value => (value.length > 300 ? 'TOO_LONG' : undefined)}
            >
              {({ fieldProps, error }) => (
                <Select
                  {...fieldProps}
                  className="single-select"
                  classNamePrefix="react-select"
                  options={[
                    { label: 'Internal', value: 'INTERNAL' },
                    { label: 'Campaign', value: 'CAMPAIGN' },
                    { label: 'Survey', value: 'SURVEY' },
                    { label: 'Advertisment', value: 'ADVERTISMENT' },
                  ]}
                  placeholder="Choose usage of the QR codes."
                />
              )}
            </Field>
            <FormFooter>
              <ButtonGroup>
                <Button onClick={() => history.goBack()} appearance="default">
                  Back
                </Button>
                <Button
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                >
                  Create
                </Button>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
`;
