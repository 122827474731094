import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet';

/**
 * Components
 */
import DynamicTable from '@atlaskit/dynamic-table';
import Button from '@atlaskit/button';
import Panel from '@atlaskit/panel';
/**
 * Hook
 */
import useEvent from '../../hooks/useEvent';

/**
 * Table Setting
 */
const head = {
  cells: [
    {
      key: 'id',
      content: 'ID',
      isSortable: true,
    },
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'description',
      content: 'Description',
    },
    {
      key: 'action',
      content: 'Action',
    },
  ],
};

const fromEventsToRows = (events, history) =>
  events.map(event => ({
    cells: [
      {
        key: 'id',
        content: event.id,
      },
      {
        key: 'name',
        content: event.name,
      },
      {
        key: 'description',
        content: <Description description={event.description} />,
      },
      {
        key: 'action',
        content: (
          <Button
            onClick={() => {
              history.push(`/event/${event.id}`);
            }}
          >
            View
          </Button>
        ),
      },
    ],
  }));

const Index = ({ match, history }: RouteComponentProps) => {
  const [rows, setRows] = useState([]);
  const { events } = useEvent();
  useEffect(() => {
    setRows(fromEventsToRows(events, history));
  }, [events]);
  return (
    <PageWrapper>
      <Helmet>
        <title>Event - View</title>
      </Helmet>
      <TableWrapper>
        <CreateButtonWrapper>
          <TableTitle>Event</TableTitle>
          <Button
            onClick={() => {
              history.push(`${match.path}/create`);
            }}
          >
            Create
          </Button>
        </CreateButtonWrapper>
        <DynamicTable
          head={head}
          rows={rows}
          rowsPerPage={10}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
          onSort={() => console.log('onSort')}
          onSetPage={() => console.log('onSetPage')}
        />
      </TableWrapper>
    </PageWrapper>
  );
};

const Description = ({ description }) => {
  const Header = <span>Press to expend</span>;
  return (
    <Panel header={Header}>
      <p>{description}</p>
    </Panel>
  );
};

const TableTitle = styled.h3`
  margin: 0;
`;

const CreateButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
`;

const TableWrapper = styled.div`
  min-width: 500px;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 20px;
  padding-bottom: 10px;
`;

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export default Index;
