import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import TextField from '@atlaskit/textfield';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, { Field, FormFooter, ErrorMessage } from '@atlaskit/form';
import TextArea from '@atlaskit/textarea';
import { createEvent } from '../../services/api/event';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet';

export default ({ history }: RouteComponentProps) => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Event - Create</title>
      </Helmet>
      <h2>Event - Create</h2>
      <Form
        onSubmit={async data => {
          const { event, errorMsg } = await createEvent(data);
          if (errorMsg) {
            // Display error message
            return;
          }
          history.goBack();
          return;
        }}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Field name="name" label="Event Name" isRequired defaultValue="">
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextField autoComplete="off" {...fieldProps} />
                  {error && (
                    <ErrorMessage>
                      This user name is already in use, try another one.
                    </ErrorMessage>
                  )}
                </Fragment>
              )}
            </Field>
            <Field
              name="description"
              label="Description"
              defaultValue=""
              isRequired
              validate={value => (value.length > 300 ? 'TOO_LONG' : undefined)}
            >
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextArea
                    minimumRows={3}
                    autoComplete="off"
                    {...fieldProps}
                  />
                  {error && (
                    <ErrorMessage>
                      Password needs to be more than 8 characters.
                    </ErrorMessage>
                  )}
                </Fragment>
              )}
            </Field>
            <FormFooter>
              <ButtonGroup>
                <Button onClick={() => history.goBack()} appearance="subtle">
                  Back
                </Button>
                <Button
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                >
                  Create
                </Button>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100%;
`;
