import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import { Helmet } from 'react-helmet';

/**
 * Layout
 */
import MainLayout from './layouts/main';
import UnauthorizedLayout from './layouts/unauthorized';

/**
 * Pages
 */
import IndexPage from './pages/index';
import EventPage from './pages/event';
import QRPage from './pages/qr';
import ActivatePage from './pages/activate';
import SurveyPage from './pages/survey';
import SignInPage from './pages/signin';

/**
 * Style
 */
import './styles/normalize.css';
import './styles/global.css';

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
});

const App: React.FC = () => {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <MainLayout>
            <PoseGroup>
              <RouteContainer key={location.pathname}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>EB Admin Parnel</title>
                </Helmet>
                <Route exact path="/" component={IndexPage} key="home" />
                <Route path="/event" component={EventPage} key="event" />
                <Route path="/qr" component={QRPage} key="qr" />
                <Route
                  path="/activate"
                  component={ActivatePage}
                  key="activate"
                />
                <Route path="/survey" component={SurveyPage} key="survey" />
                <Route
                  exact
                  path="/signin"
                  component={SignInPage}
                  key="signin"
                />
              </RouteContainer>
            </PoseGroup>
          </MainLayout>
        )}
      />
    </Router>
  );
};

export default App;
