import React from 'react';
import styled from 'styled-components';
import MediaServicesTextIcon from '@atlaskit/icon/glyph/media-services/text';
import MediaServicesDocumentIcon from '@atlaskit/icon/glyph/media-services/document';
import TaskIcon from '@atlaskit/icon/glyph/task';
import SubtaskIcon from '@atlaskit/icon/glyph/subtask';
import EditorHelpIcon from '@atlaskit/icon/glyph/editor/help';
import LinkIcon from '@atlaskit/icon/glyph/link';
import pitchSvg from '../../../../../assets/img/pitch-icon.svg'

interface Props {
  onClick(
    type: 'TEXT' | 'LONGTEXT' | 'SELECT' | 'MULTIPLESELECT' | 'PITCH'
  ): void;
}

export default ({ onClick }: Props) => (
  <ToolbarContainer>
    <ToolbarItem onClick={() => onClick('TEXT')} type="TEXT" />
    <ToolbarDivider />
    <ToolbarItem onClick={() => onClick('LONGTEXT')} type="LONGTEXT" />
    <ToolbarDivider />
    <ToolbarItem onClick={() => onClick('SELECT')} type="SELECT" />
    <ToolbarDivider />
    <ToolbarItem
      onClick={() => onClick('MULTIPLESELECT')}
      type="MULTIPLESELECT"
    />
    <ToolbarDivider />
    <ToolbarItem
      onClick={() => onClick('PITCH')}
      type="PITCH"
    />
  </ToolbarContainer>
);

const ToolbarItem = ({
  type,
  onClick,
}: {
  type: 'TEXT' | 'LONGTEXT' | 'SELECT' | 'MULTIPLESELECT' | 'PITCH' | 'CONNECTION';
  onClick?(): void;
}) => {
  return (
    <ToolbarItemWrapper onClick={onClick}>
      {getIcon(type, false)}
    </ToolbarItemWrapper>
  );
};

const getIcon = (type, inverted) => {
  switch (type) {
    case 'TEXT':
      return (
        <MediaServicesTextIcon
          primaryColor={inverted ? '#41526e' : '#fff'}
          label="text-icon"
        />
      );

    case 'LONGTEXT':
      return (
        <MediaServicesDocumentIcon
          primaryColor={inverted ? '#41526e' : '#fff'}
          secondaryColor={inverted ? '#fff' : '#41526e'}
          label="long-text-icon"
        />
      );

    case 'SELECT':
      return (
        <TaskIcon
          primaryColor={inverted ? '#41526e' : '#fff'}
          label="select-icon"
        />
      );

    case 'MULTIPLESELECT':
      return (
        <SubtaskIcon
          primaryColor={inverted ? '#41526e' : '#fff'}
          label="ms-icon"
        />
      );

    case 'PITCH':
      return (
        <PitchIcon
          src={pitchSvg}
          // primaryColor={inverted ? '#41526e' : '#fff'}
        />
      );

    case 'CONNECTION':
      return (
        <LinkIcon
          primaryColor={inverted ? '#41526e' : '#fff'}
          label="connection-icon"
        />
      );

    default:
      return (
        <EditorHelpIcon
          primaryColor={inverted ? '#41526e' : '#fff'}
          label="help-icon"
        />
      );
  }
};

const ToolbarDivider = styled.div`
  width: 10px;
  height: 90px;
`;

const ToolbarItemWrapper = styled.div`
  width: 90px;
  height: 90px;
  background: #42526d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

const ToolbarContainer = styled.div`
  display: flex;
  width: 90%;
  height: 90px;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  align-self: center;
`;

const PitchIcon = styled.img`
  width: 24px;
  height: 24px;
`