import { useEffect, useState } from 'react';
import { getToken, setToken as setCookieToken } from '../utils/cookie';
import { setAPIToken } from '../utils/request';
import { RouteComponentProps } from 'react-router';
import { signin, signout } from '../utils/auth';

export default (history: RouteComponentProps['history']) => {
  const [token, setToken] = useState(getToken());

  useEffect(() => {
    if (!token) {
      console.log('Token not exist.');
      history.push('/signin');
      return;
    }
    console.log('Token: ', token);
    setCookieToken(token);
    setAPIToken(token);
  }, [token]);

  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const { success, token } = await signin({
      email,
      password,
    });
    if (!success) return false;
    setToken(token);
    return true;
  };

  const signOut = async () => {
    await signout();
  };

  return {
    token,
    signIn,
    signOut,
  };
};
