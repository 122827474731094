import React, { useState } from 'react';
import { Layer, Rect, Circle, Text, Image } from 'react-konva';
import useImage from 'use-image';
import { QuestionBlock } from '../../../../../types';

// the first very simple and recommended way:
const ClearImage = ({ onClick }) => {
  const [image] = useImage(
    'https://img.icons8.com/flat_round/64/000000/delete-sign.png'
  );
  return (
    <Image
      onClick={onClick}
      x={200 - 12}
      y={-12}
      width={24}
      height={24}
      image={image}
    />
  );
};

const getQuestionTypeImgUrl = type => {
  switch (type) {
    case 'TEXT':
      return `https://img.icons8.com/ios-filled/50/000000/circled-t.png`;

    case 'LONGTEXT':
      return `https://img.icons8.com/ios-filled/50/000000/circled-l.png`;

    case 'SELECT':
      return `https://img.icons8.com/ios-filled/50/000000/circled-s.png`;

    case 'MULTIPLESELECT':
      return `https://img.icons8.com/ios-filled/50/000000/circled-m.png`;

    case 'PITCH':
      return `https://img.icons8.com/ios-filled/50/000000/circled-p.png`;

    default:
      return `https://img.icons8.com/metro/26/000000/error.png`;
  }
};

const QuestionType = ({ type }) => {
  const [image] = useImage(getQuestionTypeImgUrl(type));
  return <Image x={-12} y={-12} width={24} height={24} image={image} />;
};

interface Props {
  questionBlock: QuestionBlock;
  onPosUpdated?(pos: { x: number; y: number }): void;
  onDeleteClick?(id: string): void;
  onClick?(id: string): void;
}

export default ({
  questionBlock,
  onPosUpdated,
  onDeleteClick,
  onClick,
}: Props) => {
  const [pos, setPos] = useState(questionBlock.renderInformation.pos);

  const onDragMove = e => {
    const newPos = { x: e.target.x(), y: e.target.y() };
    setPos(newPos);
    onPosUpdated && onPosUpdated(newPos);
  };

  const onClearImageClear = () =>
    onDeleteClick && onDeleteClick(questionBlock.id);

  const onRectClick = () => onClick && onClick(questionBlock.id);

  return (
    <Layer draggable x={pos.x} y={pos.y} onDragMove={onDragMove}>
      <Circle x={100} y={0} radius={5} fill="#41526e" />
      <Rect
        x={0}
        y={0}
        width={200}
        height={50}
        stroke="#41526e"
        cornerRadius={5}
        onClick={onRectClick}
      />
      <Text
        y={7}
        text={questionBlock.question}
        width={200}
        align="center"
        wrap="word"
        fill="#41526e"
      />
      <Circle x={100} y={50} radius={5} fill="#41526e" />
      <ClearImage onClick={onClearImageClear} />
      <QuestionType type={questionBlock.type} />
    </Layer>
  );
};
