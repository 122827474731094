import { get, post, put, del } from '../../utils/request';

export const getAllReplies = async () => {
  const res = await get(`/reply`);
  if (res.success) {
    return {
      replies: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
}