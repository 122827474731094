import { get, post, put, del } from '../../utils/request';

export const getAllQR = async () => {
  const res = await get(`/qr`);
  if (res.success) {
    return {
      qrs: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const getAllQRByGroupId = async ({ eventId, groupId }) => {
  const res = await get(`/event/${eventId}/group/${groupId}/qr`);
  if (res.success) {
    return {
      qrs: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const createQR = async ({ eventId, groupId }) => {
  const res = await post(`/event/${eventId}/group/${groupId}/qr`);
  if (res.success) {
    return {
      qr: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const getQRImg = async ({ eventId, groupId }) => {
  const res = await get(`/event/${eventId}/group/${groupId}/qr/img`);
  if (res.success) {
    return {
      image: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const activateQR = async ({
  qrId,
  ...params
}: {
  qrId: string;
  latitude?: number;
  longitude?: number;
  locationDescription?: string;
  locationPhoto?: string;
}) => {
  const res = await post(`/qr/${qrId}/activate`, params);
  if (res.success) {
    return {
      qr: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const unactivateQR = async ({ qrId }) => {
  const res = await get(`/qr/${qrId}/unactivate`);
  if (res.success) {
    return {
      qr: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const terminateQR = async ({ qrId }) => {
  const res = await get(`/qr/${qrId}/terminate`);
  if (res.success) {
    return {
      qr: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const kiaQR = async ({ qrId }) => {
  const res = await get(`/qr/${qrId}/kia`);
  if (res.success) {
    return {
      qr: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};
