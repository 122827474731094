import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavBar } from '../components/NavBar';
import { Container } from 'react-awesome-styled-grid';
import Drawer from '@atlaskit/drawer';
import { withRouter, RouteComponentProps } from 'react-router';
import { HeaderSection, MenuSection, Item } from '@atlaskit/navigation-next';
import DashboardIcon from '@atlaskit/icon/glyph/dashboard';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import JiraCaptureIcon from '@atlaskit/icon/glyph/jira/capture';
import QuestionsIcon from '@atlaskit/icon/glyph/questions';
import ImageBorderIcon from '@atlaskit/icon/glyph/image-border';
import JiraTestSessionIcon from '@atlaskit/icon/glyph/jira/test-session';
import SignInIcon from '@atlaskit/icon/glyph/sign-in';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import { signin, signout } from '../utils/auth';
import { api } from '../utils/request';
import { getToken } from '../utils/cookie';
import useAuth from '../hooks/useAuth';

interface MainLayout extends RouteComponentProps {
  children: React.ReactChildren | React.ReactChild;
}

export default withRouter(
  ({ children, match, location, history }: MainLayout) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const handleNavigate = path => {
      history.push(path);
      setIsDrawerOpen(false);
    };
    const isInRoute = routeName => {
      const upperRoute = location.pathname.split('/')[1];
      return routeName === upperRoute;
    };
    const { token, signOut } = useAuth(history);
    useEffect(() => {
      if (!token) {
        return history.push('/signin');
      }
    }, [token]);
    return (
      <MainLayoutWrapper>
        <MainLayoutContentWrapper>
          {token && <NavBar onMenuButtonPress={() => setIsDrawerOpen(true)} />}
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </MainLayoutContentWrapper>
        <Drawer
          onClose={() => setIsDrawerOpen(false)}
          onCloseComplete={() => console.log('onDrawerCloseComplete')}
          isOpen={isDrawerOpen}
          width="wide"
        >
          <DrawerWrapper>
            <HeaderSection>
              {({ className }) => (
                <HeaderSectionText className={className}>
                  EventBinder
                </HeaderSectionText>
              )}
            </HeaderSection>
            <MenuSection>
              {({ className }) => (
                <div className={className}>
                  <Item
                    before={DashboardIcon}
                    text="Home"
                    isSelected={isInRoute('')}
                    onClick={() => handleNavigate('/')}
                  />
                  <Item
                    before={JiraTestSessionIcon}
                    text="Test"
                    onClick={() => {
                      console.log(match, location);
                      console.log(api);
                    }}
                  />
                  {/* <Item
                    before={SignInIcon}
                    text="Sign in"
                    onClick={async () =>
                      await signin({
                        email: 'admin@eventbinder.com',
                        password: '12345678',
                      })
                    }
                  /> */}
                  <Item
                    before={ImageBorderIcon}
                    text="QR"
                    isSelected={isInRoute('qr')}
                    onClick={() => handleNavigate('/qr')}
                  />
                  <Item
                    before={CalendarIcon}
                    text="Event"
                    isSelected={isInRoute('event')}
                    onClick={() => handleNavigate('/event')}
                  />
                  <Item
                    before={JiraCaptureIcon}
                    text="Activate"
                    isSelected={isInRoute('activate')}
                    onClick={() => handleNavigate('/activate')}
                  />
                  <Item
                    before={QuestionsIcon}
                    text="Mini Survey"
                    isSelected={isInRoute('survey')}
                    onClick={() => handleNavigate('/survey')}
                  />
                  <Item
                    before={SignOutIcon}
                    text="Sign out"
                    onClick={async () => {
                      await signOut();
                      window.location.href = '/';
                    }}
                  />
                </div>
              )}
            </MenuSection>
          </DrawerWrapper>
        </Drawer>
      </MainLayoutWrapper>
    );
  }
);

const MainLayoutWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const MainLayoutContentWrapper = styled.div`
  flex: 1;
  padding-top: 60px;
`;

const ChildrenWrapper = styled(Container)`
  height: calc(100% - 60px - 40px);
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const DrawerWrapper = styled.div`
  /* margin-right: 20px; */
`;

const HeaderSectionText = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;
