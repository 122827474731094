import { setAPIToken, removeAPIToken } from './request';
import { getToken, setToken, removeToken } from './cookie';
import { signin as apiSignin } from '../services/api/auth';

export const signin = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const { token, errorMsg } = await apiSignin({ email, password });
  if (errorMsg) {
    // Display error message
    return {
      success: false
    };
  }
  return {
    success: true,
    token
  }
  // Display success message
};

export const signout = async () => {
  removeToken();
  removeAPIToken();
};

const checkIfTokenExist = () => {
  const token = getToken();
  return token;
};
