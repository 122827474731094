import { useState, useEffect } from 'react';
import {
  getAllQR as apiGetAllQR,
  getAllQRByGroupId as apiGetAllQRByGroupId,
} from '../services/api/qr';

export default () => {
  const [qrs, setQRs] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllQRByGroupId = async ({
    eventId,
    groupId,
  }: {
    eventId: string;
    groupId: string;
  }) => {
    setLoading(true);
    const result = await apiGetAllQRByGroupId({ eventId, groupId });
    if (result.errorMsg) {
      setLoading(false);
      return;
    }
    setQRs(result.qrs);
    setLoading(false);
  };

  const getAllQR = async () => {
    setLoading(true);
    const result = await apiGetAllQR();
    if (result.errorMsg) {
      setLoading(false);
      return;
    }
    setQRs(result.qrs);
    setLoading(false);
  };

  // useEffect(() => {
  //   getAllQR();
  // }, []);

  return {
    qrs,
    loading,
    getAllQR,
    getAllQRByGroupId,
  };
};
