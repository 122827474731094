import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-awesome-styled-grid';

/**
 * Components
 */
import Lozenge from '@atlaskit/lozenge';
import Panel from '@atlaskit/panel';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import { RouteComponentProps, match } from 'react-router';
import useEvent from '../../../hooks/useEvent';
import useGroup from '../../../hooks/useGroup';
import { Helmet } from 'react-helmet';
import { getAllGroupsByEventId } from '../../../services/api/group';

/**
 * Table Setting
 */
const head = {
  cells: [
    {
      key: 'id',
      content: 'ID',
      isSortable: true,
    },
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'usage',
      content: 'Usage',
      isSortable: true,
    },
    {
      key: 'url',
      content: 'Url',
    },
    {
      key: 'action',
      content: 'Action',
    },
  ],
};

const fromGroupsToRows = (eventId, groups, history) => {
  return groups.map(group => ({
    cells: [
      {
        key: 'id',
        content: group.id,
      },
      {
        key: 'name',
        content: group.name,
      },
      {
        key: 'usage',
        content: group.usage,
      },
      {
        key: 'url',
        content: group.url,
      },
      {
        key: 'action',
        content: (
          <Button
            appearance="primary"
            onClick={() => {
              history.push(`/event/${eventId}/group/${group.id}`);
            }}
          >
            View
          </Button>
        ),
      },
    ],
  }));
};

const View = ({ match, history }: RouteComponentProps) => {
  const { eventId } = match.params as { eventId: string };
  const [event, setEvent] = useState({} as {
    id: string;
    name: string;
    description: string;
  });

  const { events } = useEvent();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const tempEvent = events.find(e => e.id === eventId);
    if (!tempEvent) {
      console.log('No such event');
      return;
    }
    setEvent(tempEvent);
  }, [events]);

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getAllGroupsByEventId({ eventId }).then(({ groups }) => {
      console.log(groups);
      setGroups(groups);
    });
  }, []);

  useEffect(() => {
    setRows(fromGroupsToRows(eventId, groups, history));
  }, [groups]);

  return (
    <PageWrapper>
      <Helmet>
        <title>Event - View</title>
      </Helmet>
      <Row>
        <Col xs={4} sm={8} md={2}>
          <Section>
            <SectionName>ID: </SectionName>
            <Lozenge appearance="default">
              <IDSpan>{eventId}</IDSpan>
            </Lozenge>
          </Section>
        </Col>
        <Col xs={4} sm={8} md={3}>
          <Section>
            <SectionName>Name: </SectionName>
            <Lozenge appearance="default">
              <IDSpan>{event.name}</IDSpan>
            </Lozenge>
          </Section>
        </Col>
        <Col xs={4} sm={8} md={3}>
          <DescriptionSection>
            <Panel header={DescriptionHeader}>
              <p>{event.description}</p>
            </Panel>
          </DescriptionSection>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonSection>
            <Button
              onClick={() => history.push(`/event/${eventId}/group/create`)}
            >
              Create QR Touch Points Group
            </Button>
          </ButtonSection>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableSection>
            <TableWrapper>
              <DynamicTable
                head={head}
                rows={rows}
                rowsPerPage={10}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={false}
                onSort={() => console.log('onSort')}
                onSetPage={() => console.log('onSetPage')}
              />
            </TableWrapper>
          </TableSection>
        </Col>
      </Row>
    </PageWrapper>
  );
};

const DescriptionHeader = <span>Description</span>;

const Section = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  margin: 5px;
  display: flex;
  align-items: center;
`;

const ButtonSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DescriptionSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding-left: 24px;
  padding-right: 24px;
`;

const SectionName = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const IDSpan = styled.span`
  font-size: 16px;
`;

const TableSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableWrapper = styled.div`
  min-width: 500px;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 20px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export default View;
