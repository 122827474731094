import React, { useState } from 'react';
import styled from 'styled-components';
import success from '../assets/lottie/success.json';
import Form, { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import Lottie from 'react-lottie';
import useAuth from '../hooks/useAuth';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import posed from 'react-pose';

export default ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const displayError = () => {
    setError(true);
    setTimeout(()=>setError(false), 2000);
  }
  const { token, signIn } = useAuth(history);
  const onSubmit = async data => {
    console.log('form data', data);
    try {
      setError(false);
      setLoading(true);
      const success = await signIn({
        email: data.email,
        password: data.password,
      });
      if (success) return (window.location.href = '/');
      displayError();
    } catch (error) {
      console.error(error);
      displayError();
    } finally {
      setLoading(false);
    }
  };
  return (
    <PageWrapper>
      <div>Welcome to EventBinder Admin Panel !</div>
      {token ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: success,
            rendererSettings: {
              preserveAspectRatio: 'xMinYMin slice',
            },
          }}
          height={100}
          width={100}
          isStopped={false}
          isPaused={false}
        />
      ) : (
        <SignInContainer>
          <Form onSubmit={onSubmit}>
            {({ formProps }) => (
              <form {...formProps}>
                <Field name="email" defaultValue="" label="Email" isRequired>
                  {({ fieldProps }) => <TextField {...fieldProps} />}
                </Field>
                <Field
                  name="password"
                  defaultValue=""
                  label="Password"
                  isRequired
                >
                  {({ fieldProps }) => (
                    <TextField type="password" {...fieldProps} />
                  )}
                </Field>
                <Button
                  style={{ marginTop: '10px' }}
                  type="submit"
                  appearance="primary"
                  isLoading={loading}
                >
                  Sign In
                </Button>
              </form>
            )}
          </Form>
        </SignInContainer>
      )}
      <ErrorPosed
        pose={error ? 'open' : 'close'}
        onClick={() => {
          setError(false);
        }}
      >
        <ErrorTitle>
          <ErrorIcon primaryColor="#fff" secondaryColor="#f91317" label="error" />
          Sign in fail.
        </ErrorTitle>
        <ErrorDescription>Email/Password is not correct.</ErrorDescription>
      </ErrorPosed>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
`;

const SignInContainer = styled.div`
  padding: 10px;
  width: 90%;
  height: 90%;
  max-width: 500px;
  max-height: 300px;
`;

const ErrorContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 250px;
  height: 60px;
  z-index: 10;
  background: #f91317;
  color: #fff;
  border-radius: 5px;
`;

const ErrorTitle = styled.div`
  font-size: 15px;
  font-weight: bolder;
  display: flex;
  align-items: center;
`;

const ErrorDescription = styled.div`
  margin-top: 10px;
  font-size: 13px;
  font-weight: normal;
`;

const ErrorPosed = posed(ErrorContainer)({
  open: { opacity: 1},
  close: { opacity: 0},
});
