import { get, post, put, del } from '../../utils/request';

interface APISignInResponse {
  token?: string;
  errorMsg?: string;
}

export const signin = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<APISignInResponse> => {
  const res = await post('/auth/signin', {
    email,
    password,
  });
  console.log(res)
  if (res.success) {
    return {
      token: res.data.token,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};
