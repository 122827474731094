import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import {
  getSurveyQuestion,
  createQuestion,
} from '../../../../services/api/survey';
import isEmpty from '../../../../utils/isEmpty';
import Toolbar from './components/Toolbar';
import QuestionSession from './QuestionSession';
import uuid from 'uuid/v4';
import { QuestionBlock as IQuestionBlock, Connection } from '../../../../types';

export default ({ match, history }) => {
  const { surveyId } = match.params as { surveyId: string };
  useEffect(() => {
    getSurveyQuestion({ surveyId }).then(data => {
      console.log(data);
      if (!isEmpty(data.question)) {
        const tempQuestionBlocks = [];
        const addToTempQuestionBlocks = questionBlock => {
          console.log(questionBlock);
          if (tempQuestionBlocks.map(qb => qb.id).includes(questionBlock.id))
            return;
          tempQuestionBlocks.push(questionBlock);
        };
        let row = 0;
        let col = 0;
        const displayQuestionBlock = question => {
          // console.log(question);
          if (
            question.type === 'SELECT' ||
            question.type === 'MULTIPLESELECT'
          ) {
            if (question.url && question.url.length > 0) {
              addToTempQuestionBlocks({
                ...question,
                nextQuestion: '',
                selections: question.selections.map(s => ({
                  ...s,
                  url: '',
                  nextQuestion: '',
                })),
                renderInformation: {
                  pos: {
                    x: 20 + col * 250,
                    y: 20 + row * 100,
                  },
                  connection: {
                    from: [],
                    to: [],
                  },
                },
              });
            } else if (question.nextQuestion) {
              addToTempQuestionBlocks({
                ...question,
                url: '',
                nextQuestion: question.nextQuestion.id,
                selections: question.selections.map(s => ({
                  ...s,
                  url: '',
                  nextQuestion: '',
                })),
                renderInformation: {
                  pos: {
                    x: 20 + col * 250,
                    y: 20 + row * 100,
                  },
                  connection: {
                    from: [],
                    to: [],
                  },
                },
              });
              row++;
              displayQuestionBlock(question.nextQuestion);
            } else {
              addToTempQuestionBlocks({
                ...question,
                url: '',
                nextQuestion: '',
                selections: question.selections.map(s =>
                  s.url && s.url.length > 0
                    ? {
                        ...s,
                        nextQuestion: '',
                      }
                    : s.nextQuestion
                    ? {
                        ...s,
                        url: '',
                        nextQuestion: s.nextQuestion.id,
                      }
                    : {
                        ...s,
                        url: '',
                        nextQuestion: '',
                      }
                ),
                renderInformation: {
                  pos: {
                    x: 20 + col * 250,
                    y: 20 + row * 100,
                  },
                  connection: {
                    from: [],
                    to: [],
                  },
                },
              });
              row++;
              question.selections.forEach(selection => {
                if (!selection.nextQuestion) return;
                displayQuestionBlock(selection.nextQuestion);
                col++;
              });
            }
          } else {
            console.log(question);
            if (question.url && question.url.length > 0) {
              addToTempQuestionBlocks({
                ...question,
                nextQuestion: '',
                selections: [],
                renderInformation: {
                  pos: {
                    x: 20 + col * 250,
                    y: 20 + row * 100,
                  },
                  connection: {
                    from: [],
                    to: [],
                  },
                },
              });
            } else if (question.nextQuestion) {
              addToTempQuestionBlocks({
                ...question,
                url: '',
                nextQuestion: question.nextQuestion.id,
                selections: [],
                renderInformation: {
                  pos: {
                    x: 20 + col * 250,
                    y: 20 + row * 100,
                  },
                  connection: {
                    from: [],
                    to: [],
                  },
                },
              });
              row++;
              displayQuestionBlock(question.nextQuestion);
            } else {
              addToTempQuestionBlocks({
                ...question,
                url: '',
                nextQuestion: '',
                selections: [],
                renderInformation: {
                  pos: {
                    x: 20 + col * 250,
                    y: 20 + row * 100,
                  },
                  connection: {
                    from: [],
                    to: [],
                  },
                },
              });
            }
          }
        };
        displayQuestionBlock(data.question);
        setUpdating(true);
        setQuestionBlocks(tempQuestionBlocks);
      }
    });
  }, []);

  const [updating, setUpdating] = useState(false);

  const [questionBlocks, setQuestionBlocks] = useState<IQuestionBlock[]>([]);

  const [connections, setConnections] = useState<Connection[]>([]);

  const [loading, setLoading] = useState(false);

  const addConnection = (questionBlock, connectingBlockId) => {
    // console.log('addConnection');
    // console.log(questionBlock, connectingBlockId);
    let questionBlockCopy = { ...questionBlock };
    // console.log(questionBlocks);
    let connectingBlock = questionBlocks.find(
      qb => qb.id === connectingBlockId
    );
    const connectionId = uuid();
    const newConnection = {
      id: connectionId,
      connectFrom: questionBlock.id,
      connectTo: connectingBlock.id,
    };
    questionBlockCopy.renderInformation.connection.to.push(connectionId);
    connectingBlock.renderInformation.connection.from.push(connectionId);
    return [questionBlockCopy, connectingBlock, newConnection];
  };

  useEffect(() => {
    // console.log('Question Blocks:');
    // console.log(questionBlocks);
    if (!updating) return;
    let questionBlocksCopy = [...questionBlocks];
    let connectionsCopy = [];
    let questionBlocksNeedUpdate = [];
    for (let index = 0; index < questionBlocks.length; index++) {
      let questionBlock = { ...questionBlocks[index] };
      if (
        questionBlock.selections
          .map(s => s.nextQuestion.length > 0)
          .includes(true)
      ) {
        questionBlock.selections
          .filter(s => s.nextQuestion.length > 0)
          .forEach(selection => {
            // if (!selection.nextQuestion) return;
            console.log(selection);
            const [
              questionBlockCopy,
              connectingBlock,
              newConnection,
            ] = addConnection(questionBlock, selection.nextQuestion);
            connectionsCopy.push(newConnection);
            questionBlock = questionBlockCopy;
            questionBlocksNeedUpdate.push(questionBlock, connectingBlock);
          });
      } else if (questionBlock.nextQuestion.length > 0) {
        console.log(questionBlocks);
        const [
          questionBlockCopy,
          connectingBlock,
          newConnection,
        ] = addConnection(questionBlock, questionBlock.nextQuestion);
        connectionsCopy.push(newConnection);
        questionBlock = questionBlockCopy;
        questionBlocksNeedUpdate.push(questionBlock, connectingBlock);
      }
    }
    setConnections(connectionsCopy);
    questionBlocksNeedUpdate.forEach(qb => {
      const index = questionBlocksCopy.findIndex(qbc => qbc.id === qb.id);
      if (index === -1) return;
      questionBlocksCopy[index] = qb;
      setUpdating(false);
      setQuestionBlocks(questionBlocksCopy);
    });
  }, [questionBlocks]);

  const onQuestionBlocksChanged = (newQuestionBlocks: IQuestionBlock[]) => {
    setUpdating(true);
    setQuestionBlocks(newQuestionBlocks);
  };

  const addQuestionBlock = (
    type: 'TEXT' | 'LONGTEXT' | 'SELECT' | 'MULTIPLESELECT' | 'PITCH'
  ) => {
    setQuestionBlocks([
      ...questionBlocks,
      {
        id: uuid(),
        type,
        question: '',
        description: '',
        url: '',
        nextQuestion: '',
        selections: [],
        renderInformation: {
          pos: {
            x: 0,
            y: 0,
          },
          connection: {
            from: [],
            to: [],
          },
        },
      },
    ]);
  };

  const fromQuestionBlockToQuestion = (questionBlock: IQuestionBlock) => {
    const { renderInformation, ...tempQuestionBlock } = questionBlock;
    if (
      tempQuestionBlock.type === 'SELECT' ||
      tempQuestionBlock.type === 'MULTIPLESELECT'
    ) {
      if (tempQuestionBlock.url.length > 0) {
        return {
          ...tempQuestionBlock,
          nextQuestion: '',
        };
      } else if (tempQuestionBlock.nextQuestion.length > 0) {
        const nextQuestionBlock = questionBlocks.find(
          qb => qb.id === tempQuestionBlock.nextQuestion
        );
        return {
          ...tempQuestionBlock,
          url: '',
          nextQuestion: fromQuestionBlockToQuestion(nextQuestionBlock),
        };
      } else {
        return {
          ...tempQuestionBlock,
          url: '',
          nextQuestion: '',
          selections: tempQuestionBlock.selections.map(s =>
            s.url.length > 0
              ? {
                  ...s,
                  nextQuestion: '',
                }
              : s.nextQuestion.length > 0
              ? {
                  ...s,
                  url: '',
                  nextQuestion: fromQuestionBlockToQuestion(
                    questionBlocks.find(qb => qb.id === s.nextQuestion)
                  ),
                }
              : { ...s, nextQuestion: '', url: '' }
          ),
        };
      }
    } else {
      if (tempQuestionBlock.url.length > 0) {
        return {
          ...tempQuestionBlock,
          selections: [],
          nextQuestion: '',
        };
      } else if (tempQuestionBlock.nextQuestion.length > 0) {
        const nextQuestionBlock = questionBlocks.find(
          qb => qb.id === tempQuestionBlock.nextQuestion
        );
        return {
          ...tempQuestionBlock,
          selections: [],
          url: '',
          nextQuestion: fromQuestionBlockToQuestion(nextQuestionBlock),
        };
      } else {
        return {
          ...tempQuestionBlock,
          selections: [],
          url: '',
          nextQuestion: '',
        };
      }
    }
  };

  return (
    <PageWrapper>
      <BasicInfoContainer>
        Editing Survey - {surveyId}
        <Button
          onClick={async () => {
            try {
              setLoading(true);
              // console.log(questionBlocks);
              let rootQuestionBlock = questionBlocks[0];
              const question = fromQuestionBlockToQuestion(rootQuestionBlock);
              const data = await createQuestion({
                surveyId,
                question,
              });
              window.location.href = '/survey';
            } catch (error) {
              console.error(error);
            } finally {
              setLoading(false);
            }
          }}
          appearance="primary"
          isLoading={loading}
        >
          DONE
        </Button>
      </BasicInfoContainer>
      <Toolbar onClick={type => addQuestionBlock(type)} />
      <QuestionSession
        questionBlocks={questionBlocks}
        connections={connections}
        onQuestionBlocksChanged={onQuestionBlocksChanged}
        // onConnectionsChanged={onConnectionsChanged}
      />
    </PageWrapper>
  );
};

const BasicInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 37px;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  align-self: center;
  margin-bottom: 10px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 100px);
  border-radius: 10px;
`;
