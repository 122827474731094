import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Form, { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import useAuth from '../hooks/useAuth';
import posed from 'react-pose';
import Chart from 'react-apexcharts';
import useEvent from '../hooks/useEvent';
import { Container, Row, Col } from 'react-awesome-styled-grid';
import useQR from '../hooks/useQR';
import useReplies from '../hooks/useReplies';

export default ({ history }) => {
  const [loading, setLoading] = useState(false);
  const { events, getAllEvents } = useEvent();
  const { qrs, getAllQR } = useQR();
  const { replies, getAllReplies } = useReplies();

  useEffect(() => {
    getAllQR();
    getAllReplies();
    getAllEvents();
  }, []);

  return (
    <PageWrapper>
      <Container>
        <Row>
          <Col>
            <MetricContainer>
              <MetricTitle>Number of Events</MetricTitle>
              <MetricNumber>{events.length}</MetricNumber>
            </MetricContainer>
          </Col>
          <Col>
            <MetricContainer>
              <MetricTitle>Number of QR</MetricTitle>
              <MetricNumber>{qrs.length}</MetricNumber>
            </MetricContainer>
          </Col>
          <Col>
            <MetricContainer>
              <MetricTitle>Number of Replies</MetricTitle>
              <MetricNumber>{replies.length}</MetricNumber>
            </MetricContainer>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Chart
              options={{
                chart: {
                  id: 'apexchart-example',
                },
                xaxis: {
                  categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                },
              }}
              series={[
                {
                  name: 'series-1',
                  data: [30, 40, 45, 50, 49, 60, 70, 91],
                },
              ]}
              type="bar"
              width={500}
              height={320}
            />
          </Col>
        </Row> */}
      </Container>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  height: calc(100vh - 100px);
`;

const MetricContainer = styled.div`
  padding: 20px;
  margin: 10px 0px;
  border-radius: 5px;
  background: #ffffff;
`;

const MetricTitle = styled.div`
  font-size: 20px;
`;

const MetricNumber = styled.div`
  font-size: 50px;
`;

const Spacer = styled.div`
  height: 20px;
  width: 100%;
`;
