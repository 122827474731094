import { useState, useEffect } from 'react';
import { getAllGroups as apiGetAllGroups } from '../services/api/group';

export default () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllGroups = async () => {
    setLoading(true);
    const result = await apiGetAllGroups();
    if (result.errorMsg) {
      setLoading(false);
      return;
    }
    setGroups(result.groups);
    setLoading(false);
  };

  return {
    groups,
    loading,
    getAllGroups,
  };
};
