import React, { useEffect, useState } from 'react';
import { Layer, Circle, Line } from 'react-konva';
import { QuestionBlock } from '../../../../../types';

interface Props {
  connectFrom: string;
  connectTo: string;
  questionBlocks: QuestionBlock[];
}

interface Pos {
  x: number;
  y: number;
}

export default ({ connectFrom, connectTo, questionBlocks }: Props) => {
  const getPoints = () => {
    const connectFromBlock = questionBlocks.find(qb => qb.id === connectFrom);
    const connectToBlock = questionBlocks.find(qb => qb.id === connectTo);
    // console.log('Connection(From):', connectFromBlock);
    // console.log('Connection(To):', connectToBlock);
    if (!connectFromBlock || !connectToBlock) return [];
    const connectFromPos = connectFromBlock.renderInformation.pos;
    const connectToPos = connectToBlock.renderInformation.pos;
    return [
      connectFromPos.x + 100,
      connectFromPos.y + 50,
      connectToPos.x + 100,
      connectToPos.y,
    ];
  };

  return (
    getPoints().length > 0 && (
      <Layer x={0} y={0}>
        <Line x={0} y={0} stroke="#FF8000" points={getPoints()} />
        <Circle
          x={getPoints()[0]}
          y={getPoints()[1]}
          radius={5}
          fill="#0077B5"
        />
        <Circle
          x={getPoints()[2]}
          y={getPoints()[3]}
          radius={5}
          fill="#0077B5"
        />
      </Layer>
    )
  );
};
