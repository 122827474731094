import { useEffect, useState } from 'react';
import { getAllEvents as apiGetAllEvents } from '../services/api/event';
import { api } from '../utils/request';

export default () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllEvents = async () => {
    setLoading(true);
    const result = await apiGetAllEvents();
    if (result.errorMsg) {
      setLoading(false);
      return;
    }
    setEvents(result.events);
    setLoading(false);
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  return {
    events,
    loading,
    getAllEvents,
  };
};
