import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { CameraInput } from '../../components/CameraInput';
import { ProgressDots } from '@atlaskit/progress-indicator';
import posed, { PoseGroup } from 'react-pose';
import qrcodeParser from 'qrcode-parser';
import QrReader from 'react-qr-reader';
import jsQR from 'jsqr';
import Textarea from '@atlaskit/textarea';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, { Field, FormFooter } from '@atlaskit/form';
import { ImageInput } from '../../components/ImageInput';
import { activateQR as apiActivate } from '../../services/api/qr';
import toBase64 from '../../utils/toBase64';
import useAuth from '../../hooks/useAuth';
import { Helmet } from 'react-helmet';

const ActivateStepContainer = posed.div({
  enter: { flex: 1, opacity: 1, delay: 300, beforeChildren: true },
  exit: { flex: 1, opacity: 0 },
});

export default ({ history }) => {
  useAuth(history);
  const [step, setStep] = useState(0);
  const [qrContent, setQRContent] = useState('');
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const values = ['scan', 'addon'];

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  const previousStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderScanSession = () => (
    <ScanContainer>
      {/* <CameraInput
        onChange={(file: File) => {
          // reference first file BLOB
          var reader = new FileReader();
          // it's onload event and you forgot (parameters)
          reader.onload = function(e) {
            var img = document.createElement('img');
            // the result image data
            img.src = e.target.result as any;
            img.onload = function() {
              // handle async image loading
              URL.revokeObjectURL(img.src); // free memory held by Object URL
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              context.drawImage(img, 0, 0); // draw image onto canvas (lazy method™)
              const idata = context.getImageData(0, 0, img.width, img.height);
              const content = jsQR(idata.data, img.width, img.height);
              if (!content) {
                alert('Please try again!');
                return;
              }
              console.log(content.data);
              const code = content.data;
              setQRContent(code.split('https://qr.eventbinder.app/')[1]);
              nextStep();
            };
          };
          reader.readAsDataURL(file);

          // qrcodeParser(file)
          //   .then(res => {
              // const content = res.data;
              // console.log(content);
              // setQRContent(content.split('https://qr.eventbinder.app/')[1]);
              // nextStep();
          //   })
          //   .catch(error => {
          //     alert('Please try again!');
          //   });
        }}
      /> */}
      <QrReader
        delay={300}
        onError={err => {
          console.error(err);
          alert('Please try again');
        }}
        onScan={data => {
          if (!data) return;
          console.log(data);
          setQRContent(data.split('https://qr.eventbinder.app/')[1]);
          nextStep();
        }}
        facingMode="environment"
        style={{ width: window.innerWidth * 0.95 }}
      />
    </ScanContainer>
  );

  const activateQR = async ({
    locationDescription,
    locationPhoto,
  }: {
    locationDescription: string;
    locationPhoto?: string;
  }) => {
    const { qr, errorMsg } = await apiActivate({
      qrId: qrContent,
      latitude: location.latitude,
      longitude: location.longitude,
      locationDescription,
      ...(locationPhoto && {
        locationPhoto,
      }),
    });
    if (errorMsg) {
      return false;
    }
    return true;
  };

  const renderAddonData = () => (
    <AddonContainer>
      <h3>Scanning QR: {qrContent}</h3>
      <Form
        onSubmit={async data => {
          let params = { ...data };
          if (data.locationPhoto) {
            const base64 = await toBase64(data.locationPhoto);
            params = {
              ...params,
              locationPhoto: base64,
            };
          }
          console.log(params);
          const activated = await activateQR(data);
          console.log(activated);
          if (!activated) return;
          const pressed = window.confirm('Activated !');
          console.log(pressed);
          if (pressed) {
            window.location.replace('/activate');
          }
        }}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Field
              name="locationDescription"
              label="Location Description"
              isRequired
              defaultValue=""
            >
              {({ fieldProps, error }) => (
                <Fragment>
                  <Textarea
                    minimumRows={5}
                    autoComplete="off"
                    {...fieldProps}
                  />
                </Fragment>
              )}
            </Field>
            {/* <Field
              name="locationPhoto"
              label="Location Photo"
              defaultValue=""
              validate={value => (value.length > 300 ? 'TOO_LONG' : undefined)}
            >
              {({ fieldProps: { onChange }, error }) => (
                <Fragment>
                  <ImageInput
                    text="Press Here to upload activate location image"
                    onChange={(file: File) => {
                      console.log(file);
                      onChange(file);
                    }}
                  />
                </Fragment>
              )}
            </Field> */}
            <FormFooter>
              <ButtonGroup>
                <Button onClick={() => previousStep()} appearance="subtle">
                  Back
                </Button>
                <Button
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                >
                  Activate
                </Button>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </AddonContainer>
  );

  return (
    <PageWrapper>
      <Helmet>
        <title>Activate</title>
      </Helmet>
      <ProgressDots selectedIndex={step} values={values} />
      <ActivateStepContainer>
        {step === 0 && renderScanSession()}
        {step === 1 && renderAddonData()}
      </ActivateStepContainer>
    </PageWrapper>
  );
};

const ScanContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AddonContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 32px);
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 60px - 32px);
`;
