import { get, post, put, del } from '../../utils/request';

export const getAllSurvey = async () => {
  const res = await get(`/survey`);
  if (res.success) {
    return {
      surveys: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const createSurvey = async ({
  name,
  url,
  groups
}: {
  name: string;
  url?: string;
  groups?: string[];
}) => {
  const res = await post(`/survey`, {
    name,
    ...(url && {
      url,
    }),
    groups
  });
  if (res.success) {
    return {
      survey: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const updateSurvey = async ({
  surveyId,
  name,
  url,
}: {
  surveyId: string;
  name?: string;
  url?: string;
}) => {
  const res = await put(`/survey/${surveyId}`, {
    ...(name && {
      name,
    }),
    ...(url && {
      url,
    }),
  });
  if (res.success) {
    return {
      survey: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const removeSurvey = async ({ surveyId }: { surveyId: string }) => {
  const res = await del(`/survey/${surveyId}`);
  if (res.success) {
    return {
      survey: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const addGroups = async ({
  surveyId,
  groups,
}: {
  surveyId: string;
  groups: any[];
}) => {
  const res = await post(`/survey/${surveyId}/addGroups`, {
    groups,
  });
  if (res.success) {
    return {
      survey: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const createQuestion = async ({ surveyId, question }) => {
  const res = await post(`/survey/${surveyId}/question`, {
    question,
  });
  if (res.success) {
    return {
      survey: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const getSurvey = async ({ surveyId }) => {
  const res = await get(`/survey/${surveyId}`);
  if (res.success) {
    return res.data;
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const getSurveyQuestion = async ({ surveyId }) => {
  const res = await get(`/survey/${surveyId}/question`);
  if (res.success) {
    return {
      question: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};

export const editSurvey = async ({ surveyId, survey }) => {
  const res = await put(`/survey/${surveyId}`, survey);
  if (res.success) {
    return {
      survey: res.data,
    };
  } else {
    return {
      errorMsg: res.errorMsg,
    };
  }
};
