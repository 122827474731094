import React from 'react';
import styled from 'styled-components';
import colors from '../themes/colors';
import MenuIcon from '@atlaskit/icon/glyph/menu';
import SettingsIcon from '@atlaskit/icon/glyph/settings';

interface Props {
  onMenuButtonPress(): void;
}

export const NavBar = ({ onMenuButtonPress }: Props) => (
  <NavBarWapper>
    <NavBarMenuBtn onMenuButtonPress={onMenuButtonPress} />
    <NavBarSettingBtn />
  </NavBarWapper>
);

const NavBarWapper = styled.header`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  background: #fff;
  position: fixed;
  top: 0px;
`;

const NavBarItemWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-left: ${(props: { right?: boolean; left?: boolean }) =>
    props.right && `2px solid ${colors.bg}`};
  border-right: ${(props: { right?: boolean; left?: boolean }) =>
    props.left && `2px solid ${colors.bg}`};
`;

const NavBarMenuBtn = ({ onMenuButtonPress }) => (
  <NavBarItemWrapper left onClick={onMenuButtonPress}>
    <MenuIcon label="menu-icon" primaryColor={colors.icon} />
  </NavBarItemWrapper>
);

const NavBarSettingBtn = () => (
  <NavBarItemWrapper right>
    <SettingsIcon label="setting-icon" primaryColor={colors.icon} />
  </NavBarItemWrapper>
);
