import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-awesome-styled-grid';
import Button, { ButtonGroup } from '@atlaskit/button';
import useAuth from '../../hooks/useAuth';
import useSurvey from '../../hooks/useSurvey';
import { MiniSurvey } from '../../types';
import { Helmet } from 'react-helmet';

export default ({ history }) => {
  const { surveys, getAllSurvey } = useSurvey();
  return (
    <PageWrapper>
      <Helmet>
        <title>Survey - View</title>
      </Helmet>
      <SurveyBtnGroup>
        <Button
          onClick={() => {
            history.push('/survey/create');
          }}
          appearance="primary"
        >
          Create
        </Button>
      </SurveyBtnGroup>
      {surveys.length > 0 ? (
        <SurveySession>
          {surveys.map((survey: MiniSurvey, index) => (
            <SurveyCard
              key={survey.id}
              name={survey.name}
              id={survey.id}
              connectedGroupsCount={survey.groups.length}
              repliesCount={survey.replies.length}
              history={history}
            />
          ))}
        </SurveySession>
      ) : (
        <NoSurveyContainer>
          <h2>No Survey</h2>
        </NoSurveyContainer>
      )}
    </PageWrapper>
  );
};

const SurveyCard = ({
  name,
  id,
  connectedGroupsCount,
  repliesCount,
  history,
}) => (
  <SurveyCardContainer>
    <SurveyCardTitleContainer>
      <SurveyCardTitle>{name}</SurveyCardTitle>
      <Button onClick={() => history.push(`/survey/${id}/edit`)}>Edit</Button>
    </SurveyCardTitleContainer>
    <SurveyCardSubTitle>{id}</SurveyCardSubTitle>
    <SurveyCountContainer>
      <SurveyCountWrapper>
        <SurveyCountTitle>Connected Groups</SurveyCountTitle>
        <SurveyCountNumber>{connectedGroupsCount}</SurveyCountNumber>
      </SurveyCountWrapper>
      <SurveyCountWrapper>
        <SurveyCountTitle>Replies</SurveyCountTitle>
        <SurveyCountNumber>{repliesCount}</SurveyCountNumber>
      </SurveyCountWrapper>
    </SurveyCountContainer>
    <SurveyBtnWrapper>
      <SurveyQuestionBtn
        shouldFitContainer
        onClick={() => history.push(`/survey/${id}/question`)}
      >
        Question
      </SurveyQuestionBtn>
      <Button shouldFitContainer>View Replies</Button>
    </SurveyBtnWrapper>
  </SurveyCardContainer>
);

const SurveyCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const SurveyCardTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SurveyCardTitle = styled.h2`
  font-weight: bold;
`;

const SurveyCardSubTitle = styled.p`
  font-weight: lighter;
  margin: 0px;
`;

const SurveyCountContainer = styled.div`
  display: flex;
  width: 100%;
`;

const SurveyCountWrapper = styled.div`
  flex: 1;
`;

const SurveyCountTitle = styled.h3``;

const SurveyCountNumber = styled.p`
  font-weight: lighter;
  margin: 0px;
`;

const SurveyBtnWrapper = styled.div`
  margin-top: auto;
  width: 100%;
`;

const SurveyQuestionBtn = styled(Button)`
  margin: 10px 0px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 100px);
  border-radius: 10px;
`;

const SurveyBtnGroup = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px;
`;

const SurveySession = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
`;

const NoSurveyContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
