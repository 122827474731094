import { useState, useEffect } from 'react';
import { getAllSurvey as apiGetAllSurvey } from '../services/api/survey';

export default () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllSurvey();
  }, []);

  const getAllSurvey = async () => {
    setLoading(true);
    const result = await apiGetAllSurvey();
    if (result.errorMsg) {
      setLoading(false);
      return;
    }
    setSurveys(result.surveys);
    setLoading(false);
  };

  return {
    surveys,
    loading,
    getAllSurvey,
  };
};
