import { useState, useEffect } from 'react';
import { getAllReplies as apiGetAllRelies } from '../services/api/reply';

export default () => {
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllReplies = async () => {
    setLoading(true);
    const result = await apiGetAllRelies();
    if (result.errorMsg) {
      setLoading(false);
      return;
    }
    setReplies(result.replies);
    setLoading(false);
  };

  return {
    replies,
    loading,
    getAllReplies,
  };
};
