import React, { useState } from 'react';
import styled from 'styled-components';
import { Stage } from 'react-konva';
import QuestionBlock from './components/QuestionBlock';
import ConnectionBlock from './components/ConnectionBlock';
import { QuestionBlock as IQuestionBlock, Connection } from '../../../../types';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import EditingQuestionBlockModal from './components/EditingQuestionBlockModal';

interface Props {
  questionBlocks: IQuestionBlock[];
  connections: Connection[];
  onQuestionBlocksChanged?(questionBlocks: IQuestionBlock[]): void;
  onConnectionsChanged?(questionBlocks: Connection[]): void;
}

export default ({
  questionBlocks,
  connections,
  onQuestionBlocksChanged,
  onConnectionsChanged
}: Props) => {
  const [editingQuestionBlock, setEditingQuestionBlock] = useState<
    IQuestionBlock
  >();

  const onEditingQuestionBlock = () => setEditingQuestionBlock(null);

  const onEditingQuestionChanged = (questionBlock: IQuestionBlock) => {
    let tempQuestionBlocks = [...questionBlocks];
    const index = tempQuestionBlocks.findIndex(
      qb => qb.id === questionBlock.id
    );
    if (index === -1) return;
    tempQuestionBlocks.splice(index, 1, questionBlock);
    onQuestionBlocksChanged && onQuestionBlocksChanged(tempQuestionBlocks);
    setEditingQuestionBlock(null);
  };

  const onQuestionBlockDeleteClick = qbId => {
    let tempQuestionBlocks = [...questionBlocks];
    const qbIndex = tempQuestionBlocks.findIndex(qb => qb.id === qbId);
    if (qbIndex === -1) return;
    tempQuestionBlocks.splice(qbIndex, 1);
    for (let index = 0; index < tempQuestionBlocks.length; index++) {
      let questionBlock = { ...tempQuestionBlocks[index] };
      if (questionBlock.nextQuestion === qbId) {
        questionBlock.nextQuestion = '';
      }
      let tempQuestionBlockSelection = [...questionBlock.selections];
      for (
        let selectionIndex = 0;
        selectionIndex < tempQuestionBlockSelection.length;
        selectionIndex++
      ) {
        let selection = { ...tempQuestionBlockSelection[selectionIndex] };
        if (selection.nextQuestion === qbId) {
          selection.nextQuestion = '';
          tempQuestionBlockSelection.splice(selectionIndex, 1, selection);
        }
      }
      questionBlock.selections = tempQuestionBlockSelection;
      tempQuestionBlocks.splice(index, 1, questionBlock);
    }
    onQuestionBlocksChanged && onQuestionBlocksChanged(tempQuestionBlocks);
    let tempConnections = [...connections];
    for (let index = 0; index < connections.length; index++) {
      let connection = { ...connections[index] };
      if (connection.connectFrom === qbId || connection.connectTo === qbId) {
        tempConnections.splice(index, 1);
      }
    }
    onConnectionsChanged && onConnectionsChanged(tempConnections);
  };

  return (
    <QuestionSessionContainer>
      <Stage width={900} height={window.innerHeight - 270}>
        {questionBlocks.map((questionBlock, index) => (
          <QuestionBlock
            key={index}
            questionBlock={questionBlock}
            onPosUpdated={pos => {
              onEditingQuestionChanged({
                ...questionBlock,
                renderInformation: {
                  ...questionBlock.renderInformation,
                  pos,
                },
              });
            }}
            onClick={() => setEditingQuestionBlock(questionBlock)}
            onDeleteClick={onQuestionBlockDeleteClick}
          />
        ))}
        {connections.map((connection, index) => (
          <ConnectionBlock
            key={index}
            questionBlocks={questionBlocks}
            connectFrom={connection.connectFrom}
            connectTo={connection.connectTo}
          />
        ))}
      </Stage>

      {/* Edit Question Block Modal */}
      <ModalTransition>
        {editingQuestionBlock && (
          <ModalDialog
            heading={`✏️ ${editingQuestionBlock.question}`}
            components={{
              Body: () => (
                <EditingQuestionBlockModal
                  questionBlock={editingQuestionBlock}
                  questionBlocks={questionBlocks}
                  onEditingQuestionBlockChanged={onEditingQuestionChanged}
                />
              ),
            }}
            onClose={onEditingQuestionBlock}
          />
        )}
      </ModalTransition>
    </QuestionSessionContainer>
  );
};

const QuestionSessionContainer = styled.div`
  display: flex;
  width: 90%;
  flex: 1;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  overflow-x: auto;
  overflow-y: hidden;
  align-self: center;
  margin-top: 10px;
  align-items: stretch;
`;
